import React from 'react'
import './rideCard.Module.scss'
import formatDate from 'utils/formatDate'

export interface rideProps {
  id: number
  driverName: string
  driverEmail: string
  driverPhone: string
  from: string
  to: string
  startDate: Date
  startHour: number
}

export default function RideCard({ ride }: { ride: rideProps }) {
  return (
    <div className="ride__card">
      <div className="ride__body">
        <label className="ride__date">
          {formatDate(new Date(ride.startDate))}
        </label>
        <label className="ride__title">
          <span style={{ color: 'green' }}>{ride.startHour + ':00'}</span>{' '}
          <span className="ride__arrow">|</span> {ride.from}
          <span className="ride__arrow">{' -> '}</span>
          {ride.to.split(',')[0]}
        </label>
      </div>
      <div className="ride__footer">
        <div className="ride__driver">
          <label className="driver__name">{ride.driverName}</label>
          <label className="driver__email">{ride.driverEmail}</label>
          <a href={'tel:+' + ride.driverPhone} className="driver__phone">
            {ride.driverPhone}
          </a>
        </div>
      </div>
    </div>
  )
}
