import React, { SetStateAction, useEffect } from 'react'
import './cardPopup.Module.scss'

interface CardPopupProps {
  visibility: boolean
  setVisibility: React.Dispatch<SetStateAction<boolean>>
  children: React.ReactNode
}

export default function CardPopup({
  visibility,
  setVisibility,
  children,
}: CardPopupProps) {
  useEffect(() => {
    const root = document.querySelector('body')

    if (visibility) {
      root?.classList.add('no-scroll')
    } else {
      root?.classList.remove('no-scroll')
    }
  }, [visibility])

  return (
    <>
      {visibility && (
        <>
          {' '}
          <div className="card__popup">{children}</div>
          <div
            className="card__background"
            onClick={() => setVisibility(false)}
          />
        </>
      )}
    </>
  )
}
