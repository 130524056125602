import React from 'react'
import heroImage from 'assets/images/vot.jpeg'

import './hero.Module.scss'
import Logo from 'components/ui/logo/logo'

export default function Hero() {
  return (
    <div className="hero">
      <div
        className="hero__image"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
      <div className="hero__container">
        <Logo size="big" />
        <label>
          - Alegerile europarlamentare se vor desfasura la data de 9 iunie 2024
          <br />
          - Alegerile locale in Romania se vor desfasura la data de 9 iunie
          2024.
          <br />
          Alegerile parlamentare in Romania se vor desfasura impreuna cu
          alegerile prezidentiale la data de 8 decembrie.
          <br />- Alegerile prezidentiale turul I si turul II in Romania se vor
          desfasura la data de 8 septembrie 2024 respectiv 22 septembrie 2024.
        </label>
      </div>
    </div>
  )
}
