import React from 'react'
import logoImage from 'assets/images/logo.png'
import './logo.Module.scss'

export default function Logo({
  size = 'normal',
}: {
  size?: 'normal' | 'big' | 'small'
}) {
  return (
    <div>
      <img className={`logo logo__${size}`} src={logoImage} />
    </div>
  )
}
