import React from 'react'
import './about.Module.scss'

export default function About() {
  return (
    <div className="about">
      <label className="header__title">Despre Noi</label>
      <p className="header__text">
        votdiaspora.eu este un proiect gratuit care ajuta diaspora romana sa
        voteze
      </p>
    </div>
  )
}
