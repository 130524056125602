import React, { InputHTMLAttributes } from 'react'
import './formInput.Module.scss'

interface formInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string
}

export default function FormInput({ title, ...props }: formInputProps) {
  return (
    <div className="forminput">
      {title && <div className="forminput__title">{title}</div>}
      <input className="forminput__input" {...props} />
    </div>
  )
}
