import useRidesTo from 'hooks/useRidesTo'
import React, { useEffect } from 'react'
import RideCard, { rideProps } from './components/rideCard'
import './ridesTo.Module.scss'

export default function RidesTo({ to }: { to: string }) {
  const { loading, data } = useRidesTo(to + '')

  useEffect(() => {
    if (loading) return

    console.log(data)
  }, [loading])

  return (
    <div className="rides__container">
      {data.length == 0 && (
        <label className="rides__message">
          Din pacate nu sunt curse spre {to}
        </label>
      )}
      {data.map((ride: rideProps) => {
        return <RideCard key={ride.id} ride={ride} />
      })}
    </div>
  )
}
