import { useEffect, useState } from 'react'
import { api } from 'utils/api'

export default function useRidesTo(to: string) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const fetchData = async () => {
    const response = await api.get('/rides/to/' + to)

    setData(response.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { loading, data }
}
