import axios from 'axios'

const apiKey = '3ed0d193e295452b8e6d00a19584881e'

const geolocationApi = axios.create({
  baseURL: 'https://api.geoapify.com/v1/geocode',
})

export const searchByText = async (text: string) => {
  return (await geolocationApi.get(`/search?text=${text}&apiKey=${apiKey}`))
    .data
}
