import React from 'react'
import Header from '../header/header'
import './layout.Module.scss'
import Footer from '../footer/footer'

interface layoutProps {
  children?: React.ReactNode
}

export default function Layout({ children }: layoutProps) {
  return (
    <div className="layout">
      <Header />
      <div className="layout__body">{children}</div>
      <Footer />
    </div>
  )
}
