import React from 'react'
import './header.Module.scss'
import Logo from 'components/ui/logo/logo'

export default function Header() {
  return (
    <div className="header">
      <div className="header__container">
        <Logo />
        <div className="header__body">
          <button
            onClick={() => {
              const map = document.querySelector('#map')
              map?.scrollIntoView({ behavior: 'smooth' })
            }}
            className="header__button"
          >
            Voteaza Acum
          </button>
        </div>
      </div>
    </div>
  )
}
