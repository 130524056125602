import React, { ChangeEventHandler, useState } from 'react'
import FormInput from 'components/form/formInput/formInput'
import formatDate from 'utils/formatDate'
import { api } from 'utils/api'
import { toast } from 'react-toastify'

interface FormDataProps {
  driverName: string
  driverEmail: string
  driverPhone: string
  from: string
  to: string
  startDate: string
  startHour: number
}

export default function AddRideForm({ to }: { to: string }) {
  const defaultFormData: FormDataProps = {
    driverEmail: '',
    driverName: '',
    driverPhone: '',
    from: '',
    to: to,
    startDate: formatDate(new Date()),
    startHour: 12,
  }
  const [formData, setFormData] = useState<FormDataProps>(defaultFormData)

  const validData = () => {
    console.log(formData)
    for (const key in formData) {
      console.log(key)
      if (key == 'startHour' || key == 'to' || key == 'startDate') continue
      if (
        formData[key as keyof FormDataProps] ==
        defaultFormData[key as keyof FormDataProps]
      ) {
        return false
      }
    }

    return true
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target

    setFormData((oldData) => {
      return { ...oldData, [name]: value }
    })
  }

  const submitForm = async () => {
    if (!validData()) {
      return toast.error('Toate campurile obligatorii!')
    }

    await api.post('/rides/add', {
      ...formData,
      startDate: new Date(formData.startDate),
      startHour: parseInt(formData.startHour + ''),
    })
    toast('Cursa ta a fost adaugata, multumim pentru efort!')
    setFormData(defaultFormData)
  }

  return (
    <>
      <FormInput
        onChange={handleChange}
        name="driverName"
        title="Nume"
        placeholder="Nume"
      />
      <FormInput
        onChange={handleChange}
        title="Numar Telefon"
        placeholder="Numar Telefon"
        name="driverPhone"
        value={formData.driverPhone}
      />
      <FormInput
        onChange={handleChange}
        name="driverEmail"
        title="Email"
        placeholder="Email"
        value={formData.driverEmail}
      />
      <FormInput
        onChange={handleChange}
        title="Loc Pornire"
        name="from"
        placeholder="Loc Pornire"
        value={formData.from}
      />
      <FormInput
        onChange={handleChange}
        title="Data Pornire"
        name="startDate"
        placeholder="Data Pornire"
        type="date"
        value={formData.startDate}
      />
      <FormInput
        onChange={handleChange}
        title="Ora Pornire"
        name="startHour"
        placeholder="Ora Pornire"
        type="number"
        value={formData.startHour}
      />
      <button onClick={submitForm} className="popup__button">
        Adauga Cursa
      </button>
    </>
  )
}
