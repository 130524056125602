import React, { useState } from 'react'
import CardPopup from './components/cardPopup/cardPopup'
import AddRideForm from './components/forms/addRideForm/addRideForm'
import FindRideForm from './components/findRideForm/findRideForm'
import './card.Module.scss'

//eslint-disable-next-line
export default function SectieVotareCard({ sectie }: { sectie: any }) {
  const [popupVisibility, setPopupVisibility] = useState<boolean>(false)
  const [option, setOption] = useState(1)

  return (
    <>
      <CardPopup
        visibility={popupVisibility}
        setVisibility={setPopupVisibility}
      >
        <div className="popup__header">
          <label className="card__distance">
            Distanta:{' '}
            {(
              parseInt(sectie.distance.toString().split('.')[0]) / 1000
            ).toFixed(0)}{' '}
            Km
          </label>
          <label className="card__name">
            Sectie Votare Nr {sectie.pollingStations[0].poolingStationNumber},{' '}
            {sectie.pollingStations[0].county}
          </label>

          <label className="card__footer">
            {sectie.pollingStations[0].locality},{' '}
            {sectie.pollingStations[0].address}
          </label>
        </div>
        <hr className="popup__separator" />
        <div className="popup__form">
          <label className="form__title">Cum mergi?</label>
          <div className="form__radio">
            <input
              type="radio"
              checked={option == 1}
              name="transportation"
              onChange={() => setOption(1)}
            />
            <label>Singur, disponibil pentru Car Sharing</label>
          </div>
          <div className="form__radio">
            <input
              type="radio"
              checked={option == 2}
              name="transportation"
              onChange={() => setOption(2)}
            />
            <label>Singur, fara Car Sharing</label>
          </div>
          <div className="form__radio">
            <input
              type="radio"
              name="transportation"
              checked={option == 3}
              onChange={() => setOption(3)}
            />
            <label>Fara Transport, caut Car Sharing</label>
          </div>
        </div>
        <div className="popup__body">
          {option == 1 && (
            <>
              <AddRideForm
                to={
                  sectie.pollingStations[0].locality +
                  ' ' +
                  sectie.pollingStations[0].address
                }
              />
            </>
          )}{' '}
          {option == 2 && (
            <>
              <button
                className="popup__button"
                onClick={() => setPopupVisibility(false)}
              >
                Confirma
              </button>
              <button
                className="popup__button"
                onClick={() => setPopupVisibility(false)}
              >
                Inchide
              </button>
            </>
          )}
          {option == 3 && (
            <>
              <FindRideForm sectie={sectie} />
              <button
                className="popup__button"
                onClick={() => setPopupVisibility(false)}
              >
                Inchide
              </button>
            </>
          )}
        </div>
      </CardPopup>
      <div className="card" onClick={() => setPopupVisibility(true)}>
        <div className="card__body">
          <label className="card__distance">
            Distanta:{' '}
            {(
              parseInt(sectie.distance.toString().split('.')[0]) / 1000
            ).toFixed(0)}{' '}
            Km
          </label>
          <label className="card__name">
            Sectie Votare Nr {sectie.pollingStations[0].poolingStationNumber},{' '}
            {sectie.pollingStations[0].county}
          </label>
        </div>
        <div className="card__footer">
          <label>
            {sectie.pollingStations[0].locality},{' '}
            {sectie.pollingStations[0].address}
          </label>
        </div>
      </div>
    </>
  )
}
