import React from 'react'
import infoImage from 'assets/images/unde-votez.jpeg'
import './info.Modules.scss'

export default function Info() {
  return (
    <div className="info">
      <div className="info__container">
        <div className="info__body">
          <p>
            Începând cu data de 1 aprilie 2024, cetățenii români cu domiciliul
            sau reședința în străinătate, care doresc să voteze în străinătate
            prin corespondență sau la secția de votare la scrutinul pentru
            alegerea Senatului și a Camerei Deputaților, respectiv la alegerile
            pentru Preşedintele României, se vor putea înregistra în Registrul
            electoral ca alegători în străinătate, prin intermediul unor
            formulare on-line, ce vor fi disponibile în acest portal link spre:{' '}
            <a
              target="_blank"
              href="https://votstrainatate.ro"
              rel="noreferrer"
            >
              www.votstrainatate.ro
            </a>
          </p>
        </div>
        <div
          className="info__image"
          style={{ backgroundImage: `url(${infoImage})` }}
        ></div>
      </div>
    </div>
  )
}
