import axios from 'axios'

const sectiiVotareApi = axios.create({
  baseURL: 'https://api.votdiaspora.ro/api',
})

export const getFromCoordinates = async ([lat, lon]: [
  lat: number,
  lon: number,
]) => {
  return await sectiiVotareApi.get(
    `/polling-station/near-me?latitude=${lat}&longitude=${lon}`,
  )
}
