import React from 'react'
import './banner.Module.scss'

export default function Banner() {
  return (
    <div className="banner">
      <div className="banner__container">
        <label className="banner__text">
          Vot Diaspora apare datorita nevoii persoanelor care nu au
          posibilitatea sa vina la o sectie de votare si vor sa fie ajutate sa
          ajunga sa voteze.
        </label>
      </div>
    </div>
  )
}
