import Banner from 'components/banner/banner'
import Hero from 'components/hero/hero'
import Layout from 'components/layout/layout/layout'
import Map from 'components/map/map'
import Info from 'components/info/info'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Contact from 'components/contact/contact'
import About from 'components/about/about'
import WhyVote from 'components/whyVote/whyVote'

export default function App() {
  return (
    <Layout>
      <ToastContainer />
      <Banner />
      <Hero />
      <About />
      <Info />
      <WhyVote />
      <Map />
      <Contact />
    </Layout>
  )
}
