import React, { ChangeEventHandler, useState } from 'react'
import RidesTo from '../ridesTo/ridesTo'
import FormInput from 'components/form/formInput/formInput'
import formatDate from 'utils/formatDate'
import { toast } from 'react-toastify'
import { api } from 'utils/api'

interface formDataProps {
  email: string
  name: string
  to: string
  latestDate: Date
}

//eslint-disable-next-line
export default function FindRideForm({ sectie }: { sectie: any }) {
  const nextMonth = new Date()
  nextMonth.setMonth(nextMonth.getMonth() + 1)

  const defaultFormData = {
    email: '',
    name: '',
    to:
      sectie.pollingStations[0].locality +
      ' ' +
      sectie.pollingStations[0].address,
    latestDate: nextMonth,
  }

  const [formData, setFormData] = useState<formDataProps>(defaultFormData)

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: name === 'latestDate' ? new Date(value) : value,
    })
  }

  const onSubmit = async () => {
    const response = await api.post('/rideReq/add', formData)
    console.log(response.data)
    toast('O sa te notificam cand apar curse noi! Multumesc!')
    setFormData(defaultFormData)
  }

  return (
    <>
      <RidesTo
        to={
          sectie.pollingStations[0].locality +
          ' ' +
          sectie.pollingStations[0].address
        }
      />
      <div>
        <FormInput
          title="Email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={onChange}
        />

        <FormInput
          title="Nume"
          name="name"
          placeholder="Nume"
          value={formData.name}
          onChange={onChange}
        />

        <FormInput
          title="Ultima Data"
          name="latestDate"
          value={formatDate(formData.latestDate)}
          type="date"
          onChange={onChange}
        />
      </div>
      <button onClick={onSubmit} className="popup__button">
        Notifica-ma pentru curse noi
      </button>
    </>
  )
}
