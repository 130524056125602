import React from 'react'
import './contact.Module.scss'
import { Mail, Phone } from '@mui/icons-material'

export default function Contact() {
  const phone1 = '0040742314574'
  const phone2 = '004916092387165'
  return (
    <div className="contact">
      <div className="contact__container">
        <label className="header__title">Contact</label>
        <p className="header__text">
          Contacteaza-ne pentru oricare alte nelamuriri
        </p>
        <div className="contact__body">
          <div className="contact__item">
            <div className="item__image">
              <Phone fontSize="inherit" />
            </div>
            <div className="col">
              <a href={'whatsapp://send?phone=' + phone1}>{phone1}</a>
              <a href={'whatsapp://send?phone=' + phone2}>{phone2}</a>
            </div>
          </div>

          <div className="contact__item">
            <div className="item__image">
              <Mail fontSize="inherit" />
            </div>
            <a href="mailto:votdiasporaa@gmail.com">votdiasporaa@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  )
}
