import React from 'react'
import './whyVote.Module.scss'

export default function WhyVote() {
  return (
    <div className="whyvote">
      <div className="whyvote__container">
        <label className="header__title">De ce sa Votez?</label>
        <p className="header__text">Afla de ce este important sa votezi</p>

        <div className="whyvote__body">
          <p>Votul: cel mai eficient mod prin care poți spune ce ai de spus!</p>
          <p>
            Pentru a afla mai multe despre temele aflate în discuție în campania
            electorală, o soluție ar fi să citești programele candidaților. Unii
            candidați au propria pagină pe platformele de comunicare socială,
            unde îți pot răspunde la întrebări. În perspectiva viitoarelor
            alegeri europene din 2024 poți contacta și Biroul de informare al
            Parlamentului European din regiunea ta.
          </p>

          <p>
            Dacă locuiești în străinătate poți vota la alegerile din țara de
            origine. În ceea ce privește scrutinul european, poți alege țara în
            care dorești să votezi.
          </p>

          <p>
            Drepturile de vot de care beneficiezi în UE îți permit nu numai să
            votezi, ci și să candidezi la alegerile municipale din țara în care
            locuiești. Ce-ar fi să încerci data viitoare când simți că ceva nu
            merge bine în societate?
          </p>
        </div>
      </div>
    </div>
  )
}
