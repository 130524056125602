import React, { useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import './map.Module.scss'
import 'leaflet/dist/leaflet.css'
import * as geolocationApi from 'services/geolocation'
import * as sectiiVotareApi from 'services/sectiiVotare'
import SectieVotareCard from 'components/sectieVotareCard/sectieVotareCard'

export default function Map() {
  const [coordinates, setCoordinates] = useState([
    45.827184893676026, 24.965147910215677,
  ])
  const [zoom, setZoom] = useState(7)
  const [sectii, setSectii] = useState([])
  const [mapKey, setMapKey] = useState(1)
  const [q, setQ] = useState('')

  const handleButtonClick = async () => {
    if (q.trim() == '') return
    const response = await geolocationApi.searchByText(q)
    const { lat, lon } = response.features[0].properties
    //eslint-disable-next-line
    console.log(lat, lon)
    setCoordinates([lat, lon])
    setZoom(12)
    setMapKey(mapKey + 1)

    const sectiiVotare = (await sectiiVotareApi.getFromCoordinates([lat, lon]))
      .data
    setSectii(sectiiVotare)
  }

  return (
    <div className="map">
      <div className="map__container" id="map">
        <div className="map__header">
          <label className="header__title">Unde Stai?</label>
          <p className="header__text">
            Caută orașul în care locuiești pentru a afla care sunt cele mai
            apropiate secții de votare
          </p>
          {sectii.length > 0 && (
            <label className="header__message">
              Da Click pe una din sectii pentru a oferi sau a gasi transport la
              sectie
            </label>
          )}
          <div className="header__sectii">
            {sectii.map((sectieVotare) => {
              return (
                <SectieVotareCard
                  key={Math.random() * 1000}
                  sectie={sectieVotare}
                />
              )
            })}
          </div>
          <div className="header__search">
            <input
              value={q}
              onChange={(e) => setQ(e.target.value)}
              className="header__input"
              placeholder="Cauta orasul in care stai"
            ></input>
            <button onClick={handleButtonClick} className="header__button">
              Cauta
            </button>
          </div>
        </div>
        <div className="map">
          <MapContainer key={mapKey} center={coordinates} zoom={zoom}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </div>
      </div>
    </div>
  )
}
